<template>
  <div>
    <WaBtn />
    <!-- header  -->
    <div class="fixed z-20 w-full py-4 bg-gray-900 shadow-md">
      <div class="container">
        <div class="flex items-center justify-between">
          <img src="/images/main-logo.png" alt="" class="w-20" />
          <div
            class="p-2 transition duration-200 ease-in-out transform rounded-full md:hidden hover:scale-110 hover:bg-gold-1"
          >
            <a href="tel:+60122265468">
              <img src="/images/phone.png" alt="" class="w-14" />
            </a>
          </div>
          <div class="hidden text-right text-white md:block">
            <p class="text-xl font-semibold text-white">Call Us</p>
            <div class="md:">
              <p
                class="text-xl font-bold transition duration-200 transform hover:text-gold-1 hover:scale-110"
              >
                <a href="tel:+60122265468">012-226 5468 (Grace)</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!hideEnquiry" class="bg-gray-500 h-28"></div>

    <!-- main banner  -->
    <MainBanner />

    <!-- section  -->
    <div class="py-10 lg:py-16">
      <div class="container">
        <div>
          <div>
            <h1 class="text-5xl text-gray-700 lg:text-6xl">
              The
              <span class="font-bold text-gold-1">5 Star Seminar Hall</span>
              <br class="hidden md:block" />
              Is Perfect For
            </h1>
            <div class="bg-gold-1 w-20 mt-5 h-0.5"></div>
          </div>
          <div class="pt-5 md:flex md:flex-wrap md:justify-center">
            <div
              v-for="(item, i) in hall"
              :key="i"
              class="pb-4 md:w-1/3 md:px-1"
            >
              <div class="relative">
                <img :src="item.image" :alt="item.alt" class="" />
                <p
                  class="absolute bottom-0 w-full py-1 font-medium text-center text-white bg-black bg-opacity-80"
                >
                  {{ item.alt }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- section 2 -->
    <div
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-sub.jpg')"
    >
      <div class="container md:py-10">
        <div class="lg:flex lg:items-center">
          <div class="lg:w-2/3">
            <h1 class="text-4xl font-bold text-gray-800 md:text-5xl">
              A Boss Success <span class="text-red-900">Event Space</span> Is
              Perfect For:
            </h1>
            <p
              class="pt-5 font-bold leading-tight text-gray-700 lg:leading-tight lg:text-2xl"
            >
              Training, Workshop, Seminar, Function, AGM, Conference,
              Convention, Small Exhibition, Wedding Ceremony, Birthday Party,
              Talk Show, Product Launch
            </p>
            <div class="pt-6">
              <p class="w-40 py-1 text-center text-white bg-gray-900">
                <a href="#" v-scroll-to="{ el: '#element', duration: 1500 }"
                  >Enquire Us Now</a
                >
              </p>
            </div>
          </div>
          <div class="mt-5 lg:mt-0 lg:pl-1 lg:w-1/3">
            <img
              src="/images/sub-1.png"
              alt=""
              class="mx-auto md:w-2/3 lg:w-full"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- package  -->
    <div class="py-10 bg-gray-50">
      <div class="container">
        <h1 class="text-4xl font-bold text-center">
          A Boss Success - <span class="text-gold-1">Package</span>
        </h1>

        <div class="pt-8 md:flex lg:max-w-4xl lg:mx-auto">
          <div
            v-for="(item, i) in rental"
            :key="i"
            class="pb-5 md:w-1/2 md:px-1"
          >
            <div class="bg-gradient-to-b from-gold-1 to-black">
              <h1
                class="pt-5 mx-auto text-3xl font-bold text-center text-white uppercase"
              >
                {{ item.h1 }}
              </h1>
              <div class="w-32 h-1 mx-auto mt-1 bg-white"></div>

              <div class="px-4 mt-8 text-white">
                <div
                  class="relative text-xl font-semibold text-center text-white uppercase test"
                >
                  <p>Facilities</p>
                </div>
                <div
                  v-show="item.popular"
                  class="p-2 mx-auto mt-5 text-center border-2 border-red-700 w-60"
                >
                  <p
                    class="w-full py-2 mx-auto text-2xl font-bold text-white uppercase bg-red-700"
                  >
                    {{ item.popular }}
                  </p>
                </div>
                <div class="pt-8">
                  <div
                    v-for="text in item.sublist"
                    :key="text"
                    class="flex items-center pb-3 pl-5"
                  >
                    <i class="pr-3 text-lg fa-solid fa-check"></i>
                    <p class="text-base leading-tight" :class="text.FontBold">{{ text.p }}</p>
                  </div>
                </div>
              </div>
              <!-- price -->
              <div class="py-3 bg-black bg-opacity-50" :class="item.height">
                <!-- <p class="py-5 text-5xl font-bold text-center text-white">
                  <span class="relative text-lg -top-5">RM</span>
                  {{ item.price }}
                </p> -->
                <div class="px-4 pt-3">
                  <p
                    class="py-2 font-semibold text-center text-white uppercase transition duration-200 ease-linear bg-gold-1 hover:bg-yellow-600"
                  >
                    <a href="https://wa.me/60122265468">WhatsApp Us</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- gallery  -->
    <div class="py-10">
      <div class="container">
        <h1 class="text-4xl font-bold text-center">
          Our <span class="text-gold-1">Gallery</span>
        </h1>

        <div class="pt-8">
          <carousel
            :autoplay="true"
            :loop="true"
            :dots="true"
            :autoplayTimeout="5500"
            :responsive="{
              0: { items: 1, nav: false },
              600: { items: 2, nav: false },
              1000: { items: 3, nav: false },
              1500: { items: 4, nav: false },
            }"
          >
            <div v-for="image in gallery" :key="image">
              <img :src="image + '.jpg'" alt="" />
            </div>
          </carousel>
        </div>
      </div>
    </div>

    <!-- footer  -->
    <div
      id="element"
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-enquiry.jpg')"
    >
      <div class="container lg:flex lg:items-center">
        <div class="lg:w-1/2 lg:order-2">
          <!-- enquiry form  -->
          <div class="p-6 bg-black bg-opacity-60 rounded-xl">
            <h1
              class="text-3xl font-bold leading-tight text-white capitalize lg:text-4xl"
            >
              GET IN TOUCH WITH US
            </h1>
            <EnquiryForm class="pt-8" />
          </div>
        </div>
        <div class="pt-6 lg:w-1/2 lg:pr-2">
          <img src="/images/main-logo.png" alt="" class="mx-auto w-60" />
          <div class="pt-3 text-center text-white">
            <div class="pb-5">
              <h2 class="pb-2 text-lg font-bold text-green-1">
                A Boss Success Sdn Bhd
              </h2>
              <h2 class="text-lg font-bold">Hall Location</h2>
              <div class="py-2 leading-tight text-gray-100 transition duration-300 transform rounded-md bg-gold-1 hover:scale-105">
                <a href="https://goo.gl/maps/Qz9s6myi1L37ipqx7">
                <h2 class="text-lg font-medium leading-tight md:text-xl">
                  8, Jalan Bandar 20, Pusat Bandar Puchong, 47100 Puchong,
                  Selangor
                </h2>
              </a>
              </div>
              <div class="pt-4 font-medium">
                <p>
                  Call Us At :
                  <a
                    href="tel:+60122265468"
                    class="transition duration-200 ease-in-out transform hover:text-gold-1 hover:scale-110"
                  >
                    012-226 5468 (Grace)</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-3 bg-gold-1">
      <div class="container mx-auto">
        <p class="text-xs font-medium text-center text-green-1 lg:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import carousel from "vue-owl-carousel";
import EnquiryForm from "@/components/EnquiryForm.vue";
import WaBtn from "@/components/WaBtn.vue";
import MainBanner from "@/components/MainBanner.vue";

export default {
  components: {
    carousel,
    EnquiryForm,
    WaBtn,
    MainBanner,
  },
  data() {
    return {
      hall: [
        { image: "/images/hall-1.jpg", alt: "100-150 Seats" },
        { image: "/images/hall-2.jpg", alt: "Elegant Style" },
        { image: "/images/hall-3.jpg", alt: "Led Screen" },
        { image: "/images/hall-6.jpg", alt: "Exhibition and Catering" },
        { image: "/images/hall-4.jpg", alt: "Parking Lots" },
        { image: "/images/hall-5.jpg", alt: "Clean Toilet" },
      ],
      rental: [
        {
          h1: "Package A",
          height: "adjustH",
          sublist: [
            {p:"Up To 150 Pax/ Seats"},
            { FontBold:'font-bold', p:'LED Display Signboard'},
            {p:"Italy K-Gear Speaker & Mic"},
            {p:"Lift Provided & Full Aircond"},
            {p:"Catering Space Included"},
            {p:"Display Room/ Exhibition Room Included"},
            {p:"Ideal Location with Many Parking Lots"},
            {p:"Clean Toilet"},
          ],
          price: "1,800",
        },
        {
          h1: "Package B",
          popular: "Most Popular",
          sublist: [
            {p:"Up To 150 Pax/ Seats"},
            {p:"65' Smart Digital LCD Screen", FontBold:'font-bold'},
            {p:"High-Resolution LED Screen",FontBold:'font-bold'},
            {p:"LED Display Signboard", FontBold:'font-bold'},
            {p:"Italy K-Gear Speaker & Mic"},
            {p:"Lift Provided & Full Aircond"},
            {p:"Catering Space Included"},
            {p:"Display Room/ Exhibition Room Included"},
            {p:"Ideal Location with Many Parking Lots"},
            {p:"Clean Toilet"},
          ],
          price: "2,800",
        },
      ],
      gallery: [
        "/images/gallery-1",
        "/images/gallery-2",
        "/images/gallery-3",
        "/images/gallery-4",
        "/images/gallery-5",
        "/images/gallery-6",
        "/images/gallery-7",
        "/images/gallery-8",
        "/images/gallery-9",
        "/images/gallery-10",
        "/images/gallery-11",
        "/images/gallery-12",
        "/images/gallery-13",
        "/images/gallery-14",
        "/images/gallery-15",
        "/images/gallery-16",
        "/images/gallery-17",
        "/images/gallery-18",
        "/images/gallery-19",
        "/images/gallery-20",
        "/images/gallery-21",
        "/images/gallery-22",
        "/images/gallery-23",
        "/images/gallery-24",
        "/images/gallery-25",
        "/images/gallery-26",
      ],
    };
  },
};
</script>

<style>
.test > p::before,
.test > p::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: calc(50% - 53px);
  height: 2px;
  transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  border-top: 2px solid #fff;
}
.test > p::after {
  left: auto;
  right: 0;
}
.adjustH {
  margin-top: 168px;
}
</style>
