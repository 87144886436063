<template>
  <div
    class="bg-center bg-no-repeat bg-cover"
    style="background-image: url('/images/bg-main.jpg')"
  >
    <div class="container">
      <div class="py-20 md:py-32 lg:py-36">
        <h1 class="text-4xl leading-snug text-white md:text-5xl">
          Looking for <br class="hidden md:block" />
          <span class="font-bold text-yellow-1">SEMINAR HALL RENTAL<br> IN PUCHONG?</span>
        </h1>
        <p class="pt-3 pb-2 text-sm font-bold text-white md:text-base lg:text-2xl">
          Luxury Led Screen Seminar Room, <span class="p-1 px-2 bg-red-600">Start From RM 780</span>, Suitable For
        </p>
        <p class="font-medium leading-tight text-white lg:text-lg">
          Wedding Ceremony | Birthday Party | Small Exhibition | Seminar | Meetings | Talk Show | Product Launch
        </p>
        <div v-if="!hideEnquiry" class="inline-flex pt-5 space-x-1">
          <p
            class="w-40 py-1 text-center text-white transition duration-200 bg-gold-1 hover:text-gray-900 hover:bg-white"
          >
            <a href="#" v-scroll-to="{ el: '#element', duration: 1500 }"
              >Enquire Us Now</a
            >
          </p>
          <p
            class="w-40 py-1 text-center text-white transition duration-200 bg-gold-1 hover:text-gray-900 hover:bg-white"
          >
            <a href="tel:+60122265468">Call Us Now</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
